var axios = require("axios").default;
const baseUrl = `https://api.stage.ebsr.in/`;
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
import {api} from '@/config/config'
export default class ladingBill {
    async GetLadingBillOs(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}ladingBill/fetch_lading_bill_os/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async GetLadingBillOd(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}ladingBill/fetch_lading_bill_od/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async saveBillOfLading(data) {
        try {
            const response=   await api.post(`ladingBill/add`, data, {
                headers: {
                  'Content-type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              });
            
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async GetLadingBill() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}ladingBill/view_lading_bills`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    
}